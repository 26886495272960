import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';

import { Project, ProjectContainer } from '../homepage.css';

const Index = ({ data }) => {
  const fields = JSON.parse(data.buroBorkPage.field);
  const items = data.allItems.edges;

  return (
    <Layout locale={fields.locale}>
      <Head pageTitle={fields.title} locale={fields.locale} />
      <ProjectContainer className="mainpage">
        {items.map(item => {
          const field = JSON.parse(item.node.field);
          return (
            <Project key={item.node.id} className="project">
              <img
                alt={item.node.title}
                src={
                  'https://cms.burobork.nl/images/' +
                  field.fields.Hoofdafbeelding[0].reference
                }
              />

              <Link to={'project/' + field.url} className="project-overlay">
                <div className="project-title">
                  <h2>{item.node.title}</h2>
                  <button className="button">Lees meer</button>
                </div>
              </Link>
            </Project>
          );
        })}
      </ProjectContainer>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query BuroBorkIndex {
    buroBorkPage(slug: { eq: "/" }) {
      id
      field
    }
    allItems(
      filter: { group: { eq: "Projecten" } }
      sort: { fields: id, order: DESC }
    ) {
      edges {
        node {
          id
          title
          group
          field
        }
      }
    }
  }
`;

export default Index;
