import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Project = styled.div`
  overflow: hidden;
  position: relative;

  width: 100%;
  height: 400px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  margin: 0 5px 10px;
  ${MEDIA.TABLET`
      width: 100%;
      height: 100%;
      min-height: 250px;
      height: 250px;
      img {
        position: relative;
      }
    `}

  img {
    position: absolute;
    left: 0;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    z-index: -1;
  }
  .project-overlay {
    cursor: pointer;
    width: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 2;
    bottom: -100%;
    left: 0;
    display: flex;
    flex-direction: column;
    /* height: 0px; */
    transition: all 0.5s ease;
    padding: 16px;
    ${MEDIA.TABLET`
      opacity: 1;
      height: 50px;
    `}
  }
  .project-title {
    h2 {
      color: #fff;
      text-decoration: none;
      font-size: 2.5rem;
      margin-bottom: 16px;
      ${MEDIA.TABLET`
      font-size: 1.8rem;
      margin-bottom: 8px;
    `}
    }
    p {
      color: #fff;
      margin: 5px 0px 10px 0px;
    }
  }

  .project-title button {
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
  &:hover {
    .project-overlay {
      opacity: 1;
      /* width: 100%; */
      bottom: 0;
      /* height: auto; */
    }
  }

  &:nth-child(1n + 0) {
    width: 40%;
    ${MEDIA.TABLET`
    width: 100%;
    `}
  }

  &:nth-child(2n + 0) {
    width: calc(40% - 10px);
    ${MEDIA.TABLET`
    width: 100%;
    `}
  }

  &:nth-child(3n + 0) {
    width: calc(60% - 10px);
    ${MEDIA.TABLET`
    width: 100%;
    `}
  }

  &:nth-child(4n + 0) {
    width: calc(60% - 10px);
    ${MEDIA.TABLET`
    width: 100%;
    `}
  }

  &:nth-child(5n + 0) {
    width: calc(40% - 10px);
    ${MEDIA.TABLET`
    width: 100%;
    `}
  }

  &:nth-child(6n + 0) {
    width: calc(40% - 10px);
    ${MEDIA.TABLET`
    width: 100%;
    `}
  }

  &:nth-child(7n + 0) {
    width: calc(60% - 10px);
    ${MEDIA.TABLET`
    width: 100%;
    `}
  }

  &:nth-child(9n + 0) {
    width: calc(40% - 10px);
    ${MEDIA.TABLET`
    width: 100%;
    `}
  }
  &:first-of-type {
    height: calc(100vh - 150px);
    width: 100%;
    ${MEDIA.TABLET`
    height: 250px;
    `}
  }
`;

export const ProjectContainer = styled.div`
  padding: 0px 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${MEDIA.TABLET`
         padding: 80px 16px;
         flex-direction: column;
         display: block;
    `}
`;
